import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';

import Link from './Link';
import {read, useGlobalStyles, color} from './utils';

const Cards = page => {
  const {section = false, first = false, path, images} = page;
  const [datas, setDatas] = useState();
  const classes = {...useGlobalStyles(), ...useStyles()};

  useEffect(() => {
    (async () => setDatas(await read(path)))();
  }, []); // eslint-disable-line

  if (!datas) return null;

  const highlights = datas.repeater_highlights;

  if (!highlights) return null;

  // mt="-1px" : for section dividers to be hidden under subnav
  return (
    <>
      {section && !first && <Divider />}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt="-1px"
        pt={10}
        px={{xs: 2, md: 12}}
        className={classes.fadein}
      >
        {highlights.map((highlight, idx) => {
          const image = images.find(image =>
            image.origin.tags.split(' ').includes(highlight.fields.text_highlight_thumbnail_tag)
          );
          const c = color();
          return (
            <Card key={idx} variant="outlined" className={classes.card}>
              <CardActionArea
                component={Link}
                href={highlight.fields.text_highlight_link}
                disableRipple
                focusVisibleClassName={classes.focus}
                className={classes.hover}
              >
                {!!image && (
                  <Box position="relative">
                    <CardMedia image={image.big.httpUrl} className={classes.media} />
                    <Box
                      className={clsx(classes.overlay, 'overlay')}
                      style={{backgroundColor: c}}
                    />
                  </Box>
                )}
                <CardContent>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: highlight.fields.text_highlight_description.formatted,
                    }}
                    className={clsx(classes.markdown, 'highlight')}
                    style={{color: c}}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      width: '640px',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    mixBlendMode: 'screen',
    opacity: 0.5,
    transition: 'opacity 0.3s ease',
  },
  button: {
    color: theme.palette.primary.main,
  },
}));

export default Cards;

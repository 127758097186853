import React from 'react';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import Link from './Link';
import {useGlobalStyles} from './utils';

const Footer = ({text, links, images}) => {
  const classes = useGlobalStyles();

  return (
    <Box
      width={1}
      minHeight={480}
      pt={4}
      pb={{xs: 4, md: 8}}
      px={{xs: 2, md: 12}}
      display="flex"
      flexDirection={{xs: 'column', md: 'row'}}
      justifyContent="space-between"
      position="relative"
      zIndex={10}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        width={1}
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={-10}
      >
        <Box
          component="img"
          width={0.7}
          maxHeight={0.7}
          maxWidth={1}
          mb={8}
          sx={{opacity: 0.7}}
          alt={images[1]?.origin.description}
          src={images[1]?.origin.httpUrl}
        />
        <Box
          position="absolute"
          width={1}
          height={1}
          style={{
            background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
          }}
        />
      </Box>
      <Box display="flex" alignItems={{xs: 'flex-start', md: 'flex-end'}}>
        <Box
          component="img"
          width={{xs: 288, md: 440}}
          mb={4}
          alt={images[0]?.origin.description}
          src={images[0]?.origin.httpUrl}
        />
      </Box>
      <Box display="flex" flexDirection="column" displayPrint="none">
        <Box
          flexGrow={1}
          pt={4}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems={{xs: 'flex-start', md: 'flex-end'}}
        >
          {links.map((link, idx) => (
            <ButtonBase
              key={idx}
              component={Link}
              href={link.path}
              size="small"
              disableRipple
              focusVisibleClassName={classes.focus}
              className={classes.hover}
            >
              <Typography className={classes.manier}>{link.title.toUpperCase()}</Typography>
            </ButtonBase>
          ))}
        </Box>
        <Box
          pt={4}
          textAlign={{xs: 'left', md: 'right'}}
          dangerouslySetInnerHTML={{__html: text.formatted}}
          className={classes.markdown}
        />
      </Box>
    </Box>
  );
};

export default Footer;

import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp';
import Typography from '@material-ui/core/Typography';

import {read, useGlobalStyles} from './utils';

const File = page => {
  const {path} = page;
  const [datas, setDatas] = useState();
  const classes = useGlobalStyles();

  useEffect(() => {
    (async () => setDatas(await read(path)))();
  }, []); // eslint-disable-line

  if (!datas) return null;
  if (!datas.file_block) return null;

  return (
    <Box py={4} px={{xs: 2, md: 12}} display="flex" flexWrap="wrap" className={classes.fadein}>
      {datas.file_block.map(file => (
        <Box key={file.name} mr={2} mb={1}>
          <ButtonBase
            variant={5}
            href={file.url}
            focusVisibleClassName={classes.focus}
            className={classes.hover}
          >
            <>
              <InsertDriveFileSharpIcon sx={{mr: 1}} className="icon" />
              <Typography noWrap>{file.description || file.name}</Typography>
            </>
          </ButtonBase>
        </Box>
      ))}
    </Box>
  );
};

export default File;

import React, {useState, useMemo, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {create, useGlobalStyles} from './utils';

const Contact = page => {
  const {section = false} = page;
  const {t} = useTranslation();
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const classes = {...useGlobalStyles(), ...useStyles()};

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstname: yup.string().required(t('form.required')),
        lastname: yup.string().required(t('form.required')),
        email: yup.string().required(t('form.required')).email(t('form.email')),
      }),
    [] // eslint-disable-line
  );

  const {register, setValue, watch, handleSubmit, errors, reset} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: '',
    },
  });

  useEffect(() => {
    register('firstname');
    register('lastname');
    register('email');
    register('tel');
    register('notes');
    register('type');
    register('contact');
    register('documentation');
    register('transfer');
  }, []); // eslint-disable-line

  const onChange = name => e => {
    if (e.target.value !== 'undefined') {
      setValue(name, e.target.value);
    } else if (e.target.checked !== 'undefined') {
      setValue(name, e.target.checked);
    }
  };

  const onSubmit = async data => {
    setSending(true);
    try {
      const result = await create('/contact', {form: data});
      if (result.message === 'success') setSent(true);
      setSending(false);
    } catch (e) {
      console.log(e);
      setSending(false);
    }
  };

  return (
    <Box pt={!section ? 4 : 0} px={{xs: 2, md: 12}} className={classes.fadein}>
      <Box width={1} maxWidth={{xs: 1, sm: 480}} className={classes.contact}>
        {sent ? (
          <Typography
            variant="h5"
            onClick={() => {
              reset();
              setSent(false);
            }}
          >
            {t('form.success')}
          </Typography>
        ) : (
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box py={2}>
              <FormControlLabel
                label={t('contact.option2')}
                control={<Checkbox color="primary" />}
                onChange={onChange('documentation')}
              />
              <FormControlLabel
                label={t('contact.option1')}
                control={<Checkbox color="primary" />}
                onChange={onChange('contact')}
              />
              <FormControlLabel
                label={t('contact.option3')}
                control={<Checkbox color="primary" />}
                onChange={onChange('transfer')}
              />
            </Box>
            <Box pb={2} displayPrint="none">
              <TextField
                label={t('contact.type')}
                select
                margin="none"
                fullWidth
                onChange={onChange('type')}
                value={watch('type')}
              >
                <MenuItem value="">-</MenuItem>
                <MenuItem value={1}>{t('contact.type1')}</MenuItem>
                <MenuItem value={2}>{t('contact.type2')}</MenuItem>
                <MenuItem value={3}>{t('contact.type3')}</MenuItem>
              </TextField>
            </Box>
            <Box pb={2}>
              <TextField
                label={t('contact.field1')}
                margin="none"
                fullWidth
                type="text"
                error={!!errors.firstname}
                helperText={errors.firstname?.message}
                onChange={onChange('firstname')}
              />
              <TextField
                label={t('contact.field2')}
                margin="none"
                fullWidth
                type="text"
                error={!!errors.lastname}
                helperText={errors.lastname?.message}
                onChange={onChange('lastname')}
              />
              <TextField
                label={t('contact.field3')}
                margin="none"
                fullWidth
                type="email"
                name="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                onChange={onChange('email')}
              />
              <TextField
                label={t('contact.field4')}
                margin="none"
                fullWidth
                type="tel"
                onChange={onChange('tel')}
              />
              <TextField
                label={t('contact.field5')}
                margin="none"
                fullWidth
                multiline
                minRows={4}
                type="text"
                onChange={onChange('notes')}
              />
            </Box>
            <Box pb={4} displayPrint="none">
              <Button
                variant="outlined"
                size="small"
                endIcon={sending && <CircularProgress size={16} />}
                type="submit"
              >
                {t('contact.submit')}
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  contact: {
    '& form > div > div': {
      marginBottom: theme.spacing(2),
    },
    '& label > span': {
      marginRight: theme.spacing(),
    },
    '& h5': {
      marginBottom: theme.spacing(2),
      cursor: 'pointer',
    },
  },
}));

export default Contact;

import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';

import theme from './theme';

const LetterBox = ({children, size, width, height, limits, ...props}) => {
  const md = useMediaQuery(theme.breakpoints.down('md'));

  const px = per => (width / 100) * per;
  const py = per => (height / 100) * per;
  const minx = limits.minx;
  const maxx = limits.maxx;
  const miny = limits.miny;
  const maxy = limits.maxy;

  const margin = 48;
  const sizex = maxx - minx;
  const sizey = maxy - miny;
  const scalew = size.width / (md ? px(sizex) + margin : width);
  const scaleh = size.height / (md ? py(sizey) + margin : height);

  const scale = Math.min(scalew, scaleh);
  const centerx = (50 - (minx + sizex / 2)) * scale;
  const centery = (50 - (miny + sizey / 2)) * scale;
  const diffy = height * scale < size.height ? (size.height - height * scale) / 2 : 0;

  const transform = `translateY(${-diffy}px) translate(${centerx}%, ${centery}%) scale(${scale})`;

  return (
    <Box
      position="fixed"
      top={0}
      width={size.width}
      height={size.height}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        pointerEvents: 'none',
      }}
      {...props}
    >
      <div
        style={{
          width,
          height,
          transform,
          position: 'relative',
        }}
      >
        {children}
      </div>
    </Box>
  );
};

export default LetterBox;

import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

const isExternalLink = href => /^https?:\/\//.test(href);

const Link = React.forwardRef(({href, children, ...props}, ref) => {
  const external = isExternalLink(href);

  return external ? (
    <a href={href} ref={ref} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  ) : (
    <RouterLink to={href} ref={ref} {...props}>
      {children}
    </RouterLink>
  );
});

export default Link;

import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/icons/Menu';
import {useLocation, matchPath} from 'react-router-dom';

import useStore from './useStore';
import Spotlight from './Spotlight';
import Logo from './Logo';
import Nav from './Nav';
import Footer from './Footer';
import {borderB, useGlobalStyles} from './utils';

const Layout = ({datas, children}) => {
  const {pathname} = useLocation();
  const classes = useGlobalStyles();
  const hideMenus = useStore(s => s.hideMenus);
  const set = useStore(s => s.set);
  const [drawer, setDrawer] = useState(false);

  const notRoot = matchPath(pathname, {path: '/:id'});

  const toggleDrawer = open => {
    setDrawer(open);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setDrawer(false);
  }, [pathname]); // eslint-disable-line

  useEffect(() => {
    set({hideMenus: false});
  }, [pathname, set]);

  if (!datas) return null;

  return (
    <>
      <Box
        minWidth={320}
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        className={classes.fadein}
      >
        <Box width={1} minWidth={320} position="absolute" top={0} left={0} zIndex="20">
          {!notRoot && (
            <Spotlight
              title={datas.text_spotlight_title}
              subtitle={datas.text_spotlight_subtitle}
              link={datas.page_link}
            />
          )}
          <Box width={1} display="flex" justifyContent="center">
            <Box
              width={1}
              height={{xs: '80px', md: '108px'}}
              px={{xs: 2, md: 12}}
              component="nav"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box width={1} pr={4}>
                <Box pt={1}>
                  <Logo img={datas.image_logo[0]} />
                </Box>
              </Box>
              {!hideMenus && (
                <>
                  <Box pt={{xs: '24px', md: '28px'}} displayPrint="none">
                    <IconButton
                      color="inherit"
                      edge="end"
                      onClick={() => toggleDrawer(true)}
                      disableRipple
                      focusVisibleClassName={classes.focus}
                      className={classes.hover}
                    >
                      <Menu />
                    </IconButton>
                  </Box>
                  <Drawer anchor="right" open={drawer} onClose={() => toggleDrawer(false)}>
                    <Nav pages={datas.children} border={notRoot} />
                  </Drawer>
                </>
              )}
            </Box>
          </Box>
        </Box>
        {notRoot && <Box {...borderB} displayPrint="none" />}
        <Box
          key={pathname}
          component="main"
          width={1}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          pt={notRoot ? {xs: '80px', md: '108px'} : {}}
          pb={notRoot ? 17 : {}}
          {...borderB}
        >
          {children}
        </Box>
      </Box>
      {!hideMenus && (
        <Box component="footer" minWidth={320}>
          <Footer
            images={datas.image_footer}
            text={datas.text_footer}
            links={datas.page_footer_links}
          />
        </Box>
      )}
    </>
  );
};

export default Layout;

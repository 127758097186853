import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Multiple from './Multiple';
import {read, useGlobalStyles} from './utils';

const Temple = page => {
  const {path} = page;
  const [datas, setDatas] = useState();
  const classes = useGlobalStyles();

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => setDatas(await read(`${path}?children=true`)))();
  }, []); // eslint-disable-line

  if (!datas) return null;

  return (
    <Multiple
      intro={
        <Box
          width={1}
          px={{xs: 2, md: 12}}
          display="flex"
          flexDirection={{xs: 'column', md: 'row'}}
          className={classes.fadein}
        >
          <Box width={1} flexGrow={1} pt={4}>
            <Typography variant="h6">{datas.text_name}</Typography>
            <Box
              pb={4}
              maxWidth={800}
              dangerouslySetInnerHTML={{__html: datas.text_block.formatted}}
              className={classes.markdown}
            />
          </Box>
          {datas.image_temple && (
            <Box pb={{xs: 4, md: 0}} pl={{xs: 0, md: 2}}>
              <Box
                component="img"
                width={datas.image_temple[0]?.origin.height}
                alt={datas.image_temple[0]?.origin.description}
                src={datas.image_temple[0]?.origin.httpUrl}
              />
            </Box>
          )}
        </Box>
      }
      {...datas}
    />
  );
};

export default Temple;

import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import {useGlobalStyles} from './utils';

const Slideshow = ({images, fullwidth}) => {
  const [index, setIndex] = useState(0);
  const classes = {...useGlobalStyles(), ...useStyles()};

  const total = images.length;
  const nav = inc => () => setIndex((total + ((index + inc) % total)) % total);
  const counter = `${index + 1} / ${total}`;
  const url = fullwidth ? images[index]['big']['httpUrl'] : images[index]['small']['httpUrl'];
  const description = images[index]['origin']['description'];
  const name = images[index]['name'];

  return (
    <>
      <Box px={fullwidth ? {} : {xs: 2, md: 12}}>
        <Box maxWidth={fullwidth ? null : 800} position="relative" className={classes.container}>
          <Box
            key={name}
            component="img"
            display="block"
            position="absolute"
            top={0}
            left={0}
            width={1}
            height={1}
            src={url}
            alt={description}
            className={classes.image}
          />
        </Box>
      </Box>
      <Box px={{xs: 2, md: 12}} py={1}>
        <Box
          maxWidth={fullwidth ? null : 800}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box flexGrow={1} pr={2}>
            <Typography variant="caption" display="block">
              {description}
            </Typography>
          </Box>
          {total > 1 && (
            <Box display="flex" alignItems="center" flexWrap="nowrap" displayPrint="none">
              <ButtonBase
                disableRipple
                onClick={nav(-1)}
                focusVisibleClassName={classes.focus}
                className={classes.arrow}
              >
                <Typography variant="caption" color="primary">
                  &larr;
                </Typography>
              </ButtonBase>
              <Typography variant="caption" className={classes.counter}>
                {counter}
              </Typography>
              <ButtonBase
                disableRipple
                onClick={nav(1)}
                focusVisibleClassName={classes.focus}
                className={classes.arrow}
              >
                <Typography variant="caption" color="primary">
                  &rarr;
                </Typography>
              </ButtonBase>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    '&::before': {
      content: "''",
      display: 'block',
      paddingTop: '56.25%',
    },
  },
  image: {
    objectFit: 'contain',
  },
  arrow: {
    padding: theme.spacing(0, 2),
  },
  counter: {
    width: theme.spacing(6),
    display: 'inline-block',
    textAlign: 'center',
  },
}));

export default Slideshow;

import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {read, useGlobalStyles} from './utils';
import Slideshow from './Slideshow';

const Image = page => {
  const {path} = page;
  const [datas, setDatas] = useState();
  const classes = useGlobalStyles();

  useEffect(() => {
    (async () => setDatas(await read(path)))();
  }, []); // eslint-disable-line

  if (!datas) return null;
  if (!datas.image_block) return null;

  if (datas.image_block.length > 1) {
    return (
      <Box pb={4} className={classes.fadein}>
        <Slideshow images={datas.image_block} fullwidth={datas.checkbox_fullwidth} />
      </Box>
    );
  }

  const image = datas.image_block[0];

  return (
    <Box pb={4} className={classes.fadein}>
      <Box px={datas.checkbox_fullwidth ? {} : {xs: 2, md: 12}}>
        <Box maxWidth={datas.checkbox_fullwidth ? null : 800}>
          <Box
            width={datas.checkbox_fullwidth ? 1 : null}
            maxWidth={datas.checkbox_fullwidth ? null : 1}
            component="img"
            display="block"
            alt={image['origin']['description']}
            src={datas.checkbox_fullwidth ? image['big']['httpUrl'] : image['small']['httpUrl']}
          />
        </Box>
      </Box>
      {image['origin']['description'] && (
        <Box px={{xs: 2, md: 12}} py={1}>
          <Box maxWidth={800} fontStyle="italic">
            <Typography variant="caption" display="block">
              {image['origin']['description']}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Image;

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';

import {useGlobalStyles} from './utils';

const NoMatch = () => {
  const {t} = useTranslation();
  const classes = useGlobalStyles();

  return (
    <Box pt={10} px={{xs: 2, md: 12}} className={classes.fadein}>
      <Typography>{t('main.notfound')}</Typography>
    </Box>
  );
};

export default NoMatch;

import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import useStore from './useStore';
import SubNav from './SubNav';
import {read, getComponent} from './utils';

const Multiple = ({path, intro, images, logo}) => {
  const [datas, setDatas] = useState();
  const set = useStore(s => s.set);
  const [backdrop, setBackdrop] = useState('init');
  const classes = useStyles();

  useEffect(() => {
    setBackdrop('shown');

    (async () => setDatas(await read(path)))();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!datas) return;
    if (datas.checkbox_hide_menus) set({hideMenus: true});
  }, [datas, set]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const showing = scrollTop >= 0 && scrollTop + windowHeight <= documentHeight - 500;

      if (backdrop === 'init' && showing) setBackdrop('shown');
      if (backdrop === 'shown' && !showing) setBackdrop('hidden');
      if (backdrop === 'hidden' && showing) setBackdrop('shown');
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [backdrop]);

  if (!datas) return null;

  const pages = datas.children;
  const nav = !!datas.checkbox_navigation && !!pages;

  return (
    <>
      {!!datas.checkbox_backdrop && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width={1}
          height={1}
          minHeight={320}
          zIndex="-20"
          pt={2}
          pb={{xs: 2, md: 10}}
          px={{xs: 2, md: 12}}
          display="flex"
          flexDirection="column"
          className={clsx({
            [classes.backdrop]: backdrop === 'init',
            [classes.fadein]: backdrop === 'shown',
            [classes.fadeout]: backdrop === 'hidden',
          })}
        >
          <Box
            flexGrow={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
          >
            {!!logo && (
              <Box
                component="img"
                p={2}
                width={640}
                maxWidth={1}
                maxHeight={1}
                alt={logo.origin.description}
                src={logo.origin.httpUrl}
              />
            )}
          </Box>
        </Box>
      )}
      {nav && <SubNav pages={pages.filter(page => !page.isHidden)} />}
      {intro}
      {pages?.map((page, idx) => (
        <Box key={page.id} id={page.name}>
          {nav && !page.isHidden && (
            <Box px={{xs: 2, md: 12}} display="none" displayPrint="block">
              <Typography variant="h5" gutterBottom>
                {page.title}
              </Typography>
            </Box>
          )}
          {getComponent({
            ...page,
            images,
            section: true,
            first: idx === 0,
          })}
        </Box>
      ))}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  backdrop: {
    opacity: 0,
  },
  fadein: {
    animation: '$fadein ease-in-out 500ms',
    animationFillMode: 'forwards',
  },
  '@keyframes fadein': {
    '0%': {opacity: 0},
    '50%': {opacity: 0},
    '100%': {opacity: 1},
  },
  fadeout: {
    animation: '$fadeout ease-in-out 500ms',
    animationFillMode: 'forwards',
  },
  '@keyframes fadeout': {
    '0%': {opacity: 1},
    '50%': {opacity: 0},
    '100%': {opacity: 0},
  },
}));

export default Multiple;

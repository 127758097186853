import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

import {useGlobalStyles, filterImagesByTag, color} from './utils';

const INTERVAL = 5000;

const Home = ({datas}) => {
  const classes = {...useGlobalStyles(), ...useStyles()};
  const [current, setCurrent] = useState(0);
  const [overlay, setOverlay] = useState(color());

  const logo = datas.image_logo[0];
  const images = filterImagesByTag(datas.images, 'home');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(prev => (prev + 1) % images.length);
      setOverlay(color());
    }, INTERVAL);
    return () => clearInterval(interval);
  }, [images]);

  if (!datas) return null;

  return (
    <Box
      position="relative"
      width={1}
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {images.length > 0 && (
        <>
          {images.map((image, index) => (
            <Box
              key={index}
              className={clsx(classes.slideshow, {
                [classes.active]: index === current,
                [classes.inactive]: index !== current,
              })}
              style={{backgroundImage: `url(${image.big.httpUrl})`}}
            />
          ))}
        </>
      )}

      <Box className={classes.overlay} style={{backgroundColor: overlay}} />

      {!!logo && (
        <Box
          component="img"
          p={2}
          width={640}
          maxWidth={1}
          maxHeight={1}
          alt={logo.origin.description}
          src={logo.origin.httpUrl}
          className={classes.logo}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  slideshow: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'opacity 1s ease-in-out',
    zIndex: 0,
  },
  active: {
    opacity: 0.9,
    zIndex: 2,
  },
  inactive: {
    opacity: 0,
    zIndex: 1,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.5,
    transition: 'opacity 0.3s ease',
    mixBlendMode: 'screen',
    zIndex: 3,
  },
  logo: {
    mixBlendMode: 'overlay',
    zIndex: 4,
  },
}));

export default Home;

import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import {Switch as Routes, Route, useHistory, useLocation, matchPath} from 'react-router-dom';

import useStore from './useStore';
import Filters from './Filters';
import LetterBox from './LetterBox';
import useWindowSize from './useWindowSize';
import NoMatch from './NoMatch';
import {read, getComponent, useGlobalStyles} from './utils';

const Temples = page => {
  const {path} = page;
  const history = useHistory();
  const size = useWindowSize();
  const [datas, setDatas] = useState();
  const map = useStore(s => s.map);
  const legend = useStore(s => s.legend);
  const numbers = useStore(s => s.numbers);
  const images = useStore(s => s.images);
  const filters = useStore(s => s.filters);
  const [hovered, setHovered] = useState();
  const {pathname} = useLocation();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const classes = {...useGlobalStyles(), ...useStyles()};

  const notRoot = matchPath(pathname, {path: `${path}:id`})?.params.id;

  const filtered = useCallback(
    (keywords = []) =>
      keywords.filter(keyword => filters.find(filter => filter === keyword.text_name)).length ===
      filters.length,
    [filters]
  );

  useEffect(() => {
    (async () => setDatas(await read(`${path}?children=true`)))();
  }, []); // eslint-disable-line

  useEffect(() => {
    setHovered(null);
  }, [notRoot]); // eslint-disable-line

  if (!datas) return null;

  const image = datas.image_map[0];
  const markers = datas.markers_map;
  const pages = datas.children;
  const keywords = datas.repeater_keywords.map(keyword => keyword.fields.text_name);
  const states = datas.repeater_states.map(state => state.fields);

  const locations = markers.map(marker => ({
    x: (image.origin.width / 100) * marker.x - image.origin.width / 2 - 20,
    y: (image.origin.height / 100) * marker.y - image.origin.height / 2 - 20,
    id: marker.data,
  }));

  const limits = markers.reduce(
    (acc, cur) => ({
      minx: cur.x < acc.minx ? cur.x : acc.minx,
      maxx: cur.x > acc.maxx ? cur.x : acc.maxx,
      miny: cur.y < acc.miny ? cur.y : acc.miny,
      maxy: cur.y > acc.maxy ? cur.y : acc.maxy,
    }),
    {minx: 100, maxx: 0, miny: 100, maxy: 0}
  );

  return (
    <>
      {map && (
        <LetterBox
          size={size}
          width={image.origin.width}
          height={image.origin.height}
          limits={limits}
          zIndex={-20}
          displayPrint="none"
          className={classes.fadein}
        >
          <img alt="map" src={image.origin.httpUrl} />
          {locations.map((location, idx) => {
            const {x, y} = location;
            const page = pages[idx];
            const focused = !!notRoot && notRoot === page?.name;
            const active = !!notRoot || filtered(page.page_keywords);
            const state = page.page_state;
            const style = {
              transform: `translate(${x}px, ${y}px)`,
              borderRadius: '50%',
              backgroundColor: (focused || hovered === location.id) && theme.palette.primary.light,
            };
            if (!active) return null;
            if (!!notRoot && !focused) return null;
            return (
              <div key={idx} className={classes.dot} style={style}>
                <div>
                  {numbers && !focused ? (
                    <span
                      style={{
                        color:
                          hovered === location.id
                            ? theme.palette.background.default
                            : legend
                            ? state?.text_color
                            : theme.palette.text.primary,
                      }}
                    >
                      {idx + 1}
                    </span>
                  ) : (
                    <div
                      style={{
                        backgroundColor: legend ? state?.text_color : theme.palette.divider,
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </LetterBox>
      )}

      <Routes>
        <Route path={path} exact>
          <Filters keywords={keywords} states={states} />
          <Box
            px={{xs: 2, md: 12}}
            width={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            className={classes.fadein}
          >
            {pages.map((page, idx) => {
              const active = filtered(page.page_keywords);
              const state = page.page_state;
              return (
                <ButtonBase
                  key={idx}
                  component="a"
                  disableRipple
                  href={page.path}
                  onClick={e => {
                    if (e.metaKey === false) {
                      e.preventDefault();
                      history.push(page.path);
                    }
                  }}
                  focusVisibleClassName={classes.focus}
                >
                  <Typography
                    variant={md ? 'h4' : 'h3'}
                    noWrap
                    onMouseEnter={() => active && setHovered(page.id)}
                    onMouseLeave={() => setHovered(null)}
                  >
                    <Box display="flex" alignItems="center" className="temples">
                      {images && (
                        <Box
                          component={page.image_temple?.[0] ? 'img' : 'div'}
                          alt={page.name}
                          src={page.image_temple?.[0].origin.httpUrl}
                          style={{
                            width: '1em',
                            height: '1em',
                            marginRight: '.1em',
                            opacity: active ? 1 : 0.3,
                          }}
                        />
                      )}
                      {numbers && (
                        <span
                          style={{
                            width: '1em',
                            height: '1em',
                            padding: '.1rem',
                          }}
                        >
                          <span
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: 'solid',
                              borderRadius: '50%',
                              borderWidth: '2px',
                              borderColor: active
                                ? hovered === page.id
                                  ? theme.palette.primary.main
                                  : legend
                                  ? state?.text_color
                                  : theme.palette.text.primary
                                : theme.palette.divider,
                              backgroundColor:
                                hovered === page.id
                                  ? theme.palette.primary.main
                                  : theme.palette.background.default,
                            }}
                          >
                            <span
                              style={{
                                fontSize: '.5em',
                                lineHeight: '1em',
                                color: active
                                  ? hovered === page.id
                                    ? theme.palette.background.default
                                    : legend
                                    ? state?.text_color
                                    : theme.palette.text.primary
                                  : theme.palette.divider,
                              }}
                            >
                              {idx + 1}
                            </span>
                          </span>
                        </span>
                      )}
                      <span
                        style={{
                          color: active
                            ? hovered === page.id
                              ? theme.palette.primary.main
                              : legend
                              ? state?.text_color
                              : theme.palette.text.primary
                            : theme.palette.divider,
                        }}
                      >
                        {page.title}
                      </span>
                    </Box>
                  </Typography>
                </ButtonBase>
              );
            })}
          </Box>
        </Route>
        {pages.map(page => (
          <Route key={page.path} path={page.path} exact>
            {getComponent(page)}
          </Route>
        ))}
        <Route path="*">
          <NoMatch />
        </Route>
      </Routes>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  dot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    pointerEvents: 'all',
    '& > div': {
      width: theme.spacing(5),
      height: theme.spacing(5),
      padding: theme.spacing(1.75),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& > div > span': {
      color: theme.palette.text.secondary,
      fontSize: '1.4em',
    },
    '& > div > div': {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
  },
}));

export default Temples;

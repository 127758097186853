import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import {useHistory} from 'react-router-dom';

import {useGlobalStyles} from './utils';

const Logo = ({img}) => {
  const history = useHistory();
  const classes = useGlobalStyles();

  return (
    !!img && (
      <ButtonBase
        disableRipple
        href="/"
        onClick={e => {
          if (e.metaKey === false) {
            e.preventDefault();
            history.push('/');
          }
        }}
        focusVisibleClassName={classes.focus}
        className={classes.hover}
      >
        <Box
          width={{xs: 96, md: 152}}
          dangerouslySetInnerHTML={{__html: img.svg}}
          className="logo"
        />
      </ButtonBase>
    )
  );
};

export default Logo;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import CallMadeIcon from '@material-ui/icons/CallMade';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';

import {useGlobalStyles} from './utils';

const Spotlight = ({title, subtitle, link}) => {
  const history = useHistory();
  const classes = {...useGlobalStyles(), ...useStyles()};

  if (!link) return null;

  return (
    <ButtonBase
      component="a"
      disableRipple
      href={link.path}
      onClick={e => {
        if (e.metaKey === false) {
          e.preventDefault();
          history.push(link.path);
        }
      }}
      focusVisibleClassName={classes.focus}
      className={clsx(classes.hover, classes.button)}
    >
      <Box
        width={1}
        display="flex"
        justifyContent="center"
        color="common.white"
        bgcolor="text.primary"
        className="spotlight"
      >
        <Box
          width={1}
          px={{xs: 2, md: 12}}
          py={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection={{xs: 'column', sm: 'row'}}>
            <Box display="flex" flexShrink={0} pr={2}>
              <Typography className={classes.bold}>{title}</Typography>
            </Box>
            <Box display="flex">
              <Typography
                dangerouslySetInnerHTML={{__html: subtitle.raw}}
                className={classes.bold}
              />
            </Box>
          </Box>
          <Box pl={4} display="flex" alignItems="center" flexWrap="no-wrap">
            <CallMadeIcon fontSize="small" style={{marginLeft: 4}} />
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
}));

export default Spotlight;
